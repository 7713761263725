<template>
  <div>
    <div style="float: left;">
      <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="$emit('toggleCollapsed')" />
    </div>
    <div style="float: right;">
      <a-popover title="消息通知" :overlayStyle="{'height': '60vh', 'overflow-y': 'scroll', 'overflow-x': 'hidden'}">
        <template slot="content">
          <div class="noticeList" v-for="(val, index) in messageList" :key="index" @click="onTxt(val)">
            <div class="noticeCon" :style="val.is_read === 'yes' ? '' : 'font-weight: bold'">
              <span><a-badge v-if="val.is_read === null || val.is_read === 'no'" color="black" text="black" />{{ val.publisher }}</span>
              <span class="txt">{{ val.title }}</span>
            </div>
          </div>
        </template>
        <a-badge :count="infoAccount">
          <a-icon style="font-size: 28px" type="bell" />
        </a-badge>
      </a-popover>
      <!-- <a-dropdown :trigger="['click']">
        <span class="trigger">
          {{currentWarehouse}}
          <a-icon type="down" />
        </span>
        <a-menu slot="overlay">
          <a-menu-item v-for="item in warehouseItems" :key="item.id" @click="onChangeWarehouse(item)">
            <span>{{item.name}}</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown> -->
      <a-modal
              :title="info.title"
              :visible="visible"
              @ok="visible = false"
              @cancel="visible = false"
      >
        <div class="noticeCon" style="display: block">
          <p>{{ info.details }}</p>
          <p class="txt" style="width: 100%">{{ info.pub_time }}</p>
        </div>
      </a-modal>
      <a-dropdown :trigger="['click']">
        <span class="trigger">
          {{username}}
          <a-icon type="down" />
        </span>
        <a-menu slot="overlay">
          <a-menu-item @click="$router.push('/user/set_password')">
            <span>修改密码</span>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item @click="logout">
            <span>退出登录</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';
  import { EventSourcePolyfill } from 'event-source-polyfill';
  import { noticeRead } from "@/api/user";
  export default {
    name: 'Headbar',
    props: ['collapsed', 'username'],
    inject: ['reloadPage'],
    data() {
      return {
        eventSource: null,
        infoAccount: 0,
        info: '',
        visible: false,
        timer: null,
        messageList: [],
        warehouseItems: [],
        currentWarehouse: '所有仓库',
      };
    },
    methods: {
      async initData() {
        let that = this
        this.eventSource = new EventSourcePolyfill(`${process.env.VUE_APP_NEW_URL}/excel/MessageNotifyApi`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('access')}`
          }
        });
        this.eventSource.onmessage = function(event) {
          const eventData = JSON.parse(event.data);
          that.messageList.push(...eventData)
          let arr = []
          that.messageList.forEach(item => {
            if(item.is_read === 'no' || item.is_read === null) {
              arr.push(item.is_read)
            }
          })
          that.infoAccount = arr.length
        };
        this.eventSource.onopen = () => {
          this.messageList = []; // 每次重连时清空数据
        };
      },
      logout() {
        Cookies.remove('access');
        Cookies.remove('refresh');
        clearInterval(this.timer)
        this.$router.push('/user/login');
      },
      onChangeWarehouse(item) {
        this.currentWarehouse = item.name;
        this.$store.commit('setWarehouse', item.id);
        this.reloadPage();
      },
      onTxt(val) {
        if(val.is_read === null || val.is_read === 'no') {
          noticeRead({ notice_id: val.id })
                  .then(() => {
                    this.infoAccount -= 1
                    val.is_read = 'yes'
                  })
                  .catch(() => {
                    this.$message.error('请求出错');
                  });
        }
        this.visible = true
        this.info = val
      }
    },
    beforeDestroy() {
      if (this.eventSource) {
        this.eventSource.close(); // 关闭 SSE 连接
      }
    },
    destroyed() {
      clearInterval(this.timer)
    },
    created() {
      this.initData();
    },
  }
</script>

<style scoped lang="less">
  ::v-deep .ant-modal-wrap{
    z-index: 999999;
  }
  .noticeList{
    width: 600px;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 0 20px;
    border-bottom: 1px solid #ddd;
  }
  .noticeCon{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .txt{
      width: 40%;
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }
</style>
